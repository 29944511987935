.tags-input {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 48px;
    width: 100%;
    padding: 0 8px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 6px;
}
.tags-input:focus-within {
    border: 1px solid rgba(0, 0, 0, 0.08);
}
.tags-input input {
    flex: 1;
    border: none;
    height: 46px;
    font-size: 14px;
    padding: 4px 0 0 0;
}
.tags-input input:focus {
    outline: transparent;
}
#tags {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 8px 0 0 0;
}
.tag {
    width: auto;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.87);
    padding: 0 8px;
    font-size: 14px;
    list-style: none;
    border-radius: 16px;
    margin: 0 8px 8px 0;
    background: rgba(0, 0, 0, 0.08);
}
.tag .tag-title {
    margin-top: 3px;
}
.tag .tag-close-icon {
    display: block;
    width: 16px;
    height: 16px;
    line-height: 14px;
    text-align: center;
    font-size: 14px;
    margin-left: 8px;
    color: white;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.26);
    cursor: pointer;
}
